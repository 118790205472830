const apiURLs = {
  production: 'https://api.netmin.cl',
  staging: 'https://api.minexdigital.space',
  development: 'https://api.netmin.dev',
}

const siteURLs = {
  production: 'https://netmin.cl',
  staging: 'https://app.minexdigital.space',
  development: 'https://webapp.netmin.dev',
}

const panelURLs = {
  production: 'https://panel.netmin.cl',
  staging: 'https://panel.minexdigital.space',
  development: 'https://panel.netmin.dev',
}

const chatURLs = {
  production: 'https://chat.netmin.cl',
  staging: 'https://chat.minexdigital.space',
  development: 'https://chat.netmin.dev',
}

const webinarChatURLs = {
  production: 'https://chatto.netmin.cl',
  staging: 'https://chatto.minexdigital.space',
  development: 'https://chatto.netmin.dev',
}

const baseDomains = {
  production: '.netmin.cl',
  staging: '.minexdigital.space',
  development: '.netmin.dev',
}

const environment = process.env.REACT_APP_ENVIRONMENT || 'development'

console.log('Environment:', process.env.REACT_APP_ENVIRONMENT)

export const isProductionENV = process.env.REACT_APP_ENVIRONMENT === 'production'

export const apiURL = apiURLs[environment]

export const siteName = 'Netmin | La Red Profesional de la Minería'

export const siteDescription =
  'Netmin es un ecosistema profesional para generar redes y colaboración en pro de los desafíos que tiene la industria.'

export const siteURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : siteURLs[environment]

export const panelURL = panelURLs[environment]

export const chatURL = chatURLs[environment]

export const webinarChatURL = webinarChatURLs[environment]

export const baseDomain = process.env.NODE_ENV === 'development' ? 'localhost' : baseDomains[environment]

export const googleMapsAPIKey = isProductionENV
  ? 'AIzaSyDlT9gLO0IF4thuyznKoya9blUEUrdwAUQ'
  : 'AIzaSyAjN1oZOf4-imk-KpZX8mtiyiLPbWMiOO4'

// For logins
export const googleClientID = !isProductionENV
  ? '58722587915-2cu97sukhgh4qh9mho4c3vs3k9k09243.apps.googleusercontent.com'
  : '477482471195-nvvl25l2qhqve0eehrq8k9h03au4grq8.apps.googleusercontent.com'

export const linkedInClientID = '7789rjc1uoahp9'
export const linkedInScope = 'r_liteprofile r_emailaddress'
export const linkedInState = 'bmV0bWluTGlua2VkaW5Mb2dpbg'

// DEV/LOCAL - MUX TRAXO  PROD - MUX NETMIN
export const muxEnvKey = !isProductionENV ? 'qk63vg7kuj9phj7k3ioohohtc' : 'dr475nnorfni3iogij44158jp'

// Array of regular expressions that defines which routes must be opened as modals when
// the navigation event occurs within the web app
// In other words: When you click a link, a modal shows the route, but if you refresh, the route uses the entire viewport.
export const modalRoutes = [
  '^\\/p\\/[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}', // Match: /p/[uuid]
  '^\\/activities\\/[a-e]\\/[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}', // Match: /activities/[a|e]/[uuid]
  '^\\/a\\/[0-9]{4}\\/[0-9]{2}\\/[0-9]{1,2}', // Match: /a/[year]/[month/[day]
]

export const earlyAccessCodes = [
  'Z2UCG85HJ7',
  'PLMPMX2AGP',
  'QJXK95MDJW',
  'THQD7GN4VC',
  'GE8Y25MYE2',
  'JFJM4FMVJ5',
  '8NPY4NHJ8V',
  'PPQZS96XTH',
  '3AH5EN79SP',
  'Z3QUDNBRCH',
]
